import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';
import './header.scss';
import Logo from '../images/logo.png';
import { useEffect, useRef, useState } from 'react';
function Header() {

  const navRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        const navHeight = navRef.current.clientHeight;
        const scrollTop = window.scrollY;

        if (scrollTop > navHeight) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar expand="lg" ref={navRef} className={isScrolled ? 'my-nav scrolled' : 'my-nav'}>
      <Container>
        <Navbar.Brand href="#home">
          <img src={Logo} alt='logo' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink className='each-nav' to='/'>Home</NavLink>
            <NavLink className='each-nav' to='/'>About me</NavLink>
            <NavLink className='each-nav' to='/'>Projects</NavLink>
            <NavLink className='each-nav' to='/'>Contact</NavLink>            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Header;