import React, { useState, useRef } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import contact from '../../images/contact.png';
import emailjs from '@emailjs/browser';
import SweetAlert2 from 'react-sweetalert2';

function ContactForm() {
  const [swalProps, setSwalProps] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = '*Name is required';
    }
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = '*Email is invalid';
    }
    if (!formData.message.trim()) {
      errors.message = '*Message is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {

      setFormData({
        name: '',
        email: '',
        message: ''
      })

      emailjs.sendForm('service_081tz4b', 'template_tl0mmli', form.current, {
        publicKey: 'aTUAatc4w6mEZ9Mfn',
      })
        .then(
          () => {
            console.log('SUCCESS!');
            setSwalProps({
              show: true,
              title: 'Congratulations!',
              text: 'Message has delivered successfully.',
          });
          setTimeout(()=>{
            setSwalProps({
              show: false,
            })
          },2000)
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );

    }
    else {
      console.log('Form has errors, cannot submit');
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <section className="contact">
        <Container>
          <Row>
            <Col md={12} className='text-center'>
              <h2 className="site-head">Get a quote</h2>
              <p className='mb-5'>Ask any question, any queries for project or to get a quote. Set a meeting with me.</p>
            </Col>
            <Col md={5}>
              <Row>
                <Col md={12}>
                  <div className="each-input">
                    <input
                      type='text'
                      placeholder='Your name'
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <span className="error">{errors.name}</span>}
                  </div>


                </Col>
                <Col md={12}>
                  <div className="each-input">
                    <input
                      type='text'
                      placeholder='Your email/phone'
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                  </div>

                </Col>
                <Col md={12}>
                  <div className="each-input">
                    <textarea
                      placeholder='Write your query'
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    ></textarea>
                    {errors.message && <span className="error">{errors.message}</span>}
                  </div>

                </Col>
                <Col md={12}>
                  <Button className='w-100 btn-dark' type='submit' value="Send">Submit</Button>
                </Col>
              </Row>
            </Col>
            <Col md={7} className='d-none d-md-block'>
              <div className="contact-txt">
                <img src={contact} alt="" className='con-img' />
                <h4>
                  <span>Build,</span><br /> your Dream Website <br /> In pocket frindly Budget
                </h4>
                <small><span>*</span>Get SEO service, Web Content, Digital Marketing</small>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <SweetAlert2 {...swalProps} />
    </form>

  )
}

export default ContactForm