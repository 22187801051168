import React, { } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import './main.scss'
import FacebookIcon from '../../images/facebook.png';
import GitIcon from '../../images/github.png';
import YoutubeIcon from '../../images/youtube.png';
import myImg from '../../images/me.png';
import SideMob from '../../images/mob.png';
import Pro1 from '../../images/project1.png';
import Pro2 from '../../images/project1.png';
import Pro3 from '../../images/project1.png';
import Pro4 from '../../images/project1.png';
import ContactForm from './ContactForm';
function Main() {
  const items = [Pro1, Pro2, Pro3, Pro4];



  return (
    <>
      <section className="banner">
        <Container>
          <Row>
            <Col md={7} className='order-md-1 order-2 gg'>
              <div className="ban-txt">
                <span>Hey I'm</span>
                <h1>Avishek</h1>
                <span>{'{Frontend Developer}.'}</span>
                <p>Welcome to my portfolio! I'm Avishek Das, a seasoned frontend developer with 6.3 years of experience in crafting captivating digital experiences. Armed with HTML, CSS, and JavaScript, I bring ideas to life with React and React Native, seamlessly blending creativity with functionality. Whether it's building dynamic web applications or immersive mobile experiences, I thrive on pushing boundaries and delivering pixel-perfect solutions. With a knack for fast problem-solving and a passion for innovation, I tackle challenges head-on, always striving to exceed expectations. Let's collaborate and create something extraordinary together.</p>
                <ul>
                  <li>Follow Me:</li>
                  <li><a href='visionaryavi.in'><img src={FacebookIcon} alt='facebook-icon' /></a></li>
                  <li><a href='visionaryavi.in'><img src={GitIcon} alt='github-icon' /></a></li>
                  <li><a href='visionaryavi.in'><img src={YoutubeIcon} alt='youtube-icon' /></a></li>
                </ul>
              </div>
              <div className="strength">
                <div className="each-strength">
                  <h5>HTML,CSS,JS</h5>
                  <p>Crafts immersive web experiences using HTML, CSS, and JavaScript, seamlessly blending aesthetics with functionality to deliver captivating user interfaces.</p>
                </div>
                <div className="each-strength">
                  <h5>React.js, React Native</h5>
                  <p>Demonstrates mastery in building dynamic and responsive applications with React and React Native, showcasing an intuitive understanding of user interaction and interface design principles.</p>
                </div>
                <div className="each-strength">
                  <h5>Redux, Node.js</h5>
                  <p>Adeptness in Node.js and Redux empowers him to architect scalable and efficient back-end systems, ensuring data flows seamlessly and state management remains organized with meticulous attention to detail.</p>
                </div>
              </div>
            </Col>
            <Col md={5} className='order-md-2 order-1 text-center'>
              <img src={myImg} alt="ban-img" className='ban-img' />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="ui-ux">
        <h2 className='side-txt'><span>UI</span>/UX</h2>
        <Container>
          <Row>
            <Col md={8} className='order-md-1 order-2'>
              <p>Crafting captivating user interfaces. My skill set encompasses a keen eye for graphics, proficient in tools like Figma and Photoshop. But that's just the tip of the iceberg! Avishek's expertise extends to video editing for YouTube, adding a dynamic dimension to my repertoire.</p>

              <p>What truly sets me apart is the ability to translate modern UI designs into interactive UX experiences that captivate and engage users. With a passion for creating seamless interactions, Avishek transforms static designs into living, breathing interfaces that not only look stunning but also function intuitively. Whether it's designing sleek interfaces or orchestrating user journeys that resonate, Avishek Das is your go-toexpert for UI/UX excellence. With a knack for blending aesthetics with functionality, Avishek brings your digital visions to life, ensuring an experience that not only meets but exceeds expectations. </p>
              <button className='site-btn'>Read More</button>
            </Col>
            <Col md={4} className='order-md-2 order-1'>
              <img src={SideMob} alt='mobile' />
            </Col>
          </Row>

        </Container>
      </section>

      <section className="journey" id="sec3">
        <Container className='text-center'>
          <h2 className="site-head">My Journey</h2>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>

          <Row>
            <Col md={10} className='m-auto'>
              <ul className='timeline'>
                <li>
                  <div className="each-timeline">
                    <span>2018</span>
                    <div className='time-txt'>
                      <h4>My journey has started</h4>
                      <p>In 2018, I have embarked on my journey as a frontend developer, armed with ambition and a hunger to make my mark in the tech world.</p>

                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2019</span>
                    <div className='time-txt'>
                      <h4>Experinceing corporate lifestyle</h4>
                      <p>As 2019 dawned, I found myself navigating the intricacies of corporate life, honing my skills amidst the hustle and bustle of the industry.</p>

                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2020</span>
                    <div className='time-txt'>
                      <h4>Pandemic and new regular</h4>
                      <p>Then came 2020, a year etched in history with the onset of a global pandemic. Undeterred by the challenges, I've adapted to the new normal, leveraging my expertise to craft innovative solutions in a rapidly changing landscape.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2021</span>
                    <div className='time-txt'>
                      <h4>Hard work and achivements</h4>
                      <p>By 2021, my perseverance paid off, earning me recognition as one of the finest frontend developers in the field. Fuelled by determination, I've achieved remarkable milestones, setting a precedent for excellence.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2022</span>
                    <div className='time-txt'>
                      <h4>Evolving new skills</h4>
                      <p>As 2022 dawned, my journey took a transformative turn as I delved into the realm of new technologies, evolving my skill set to stay ahead of the curve.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2023</span>
                    <div className='time-txt'>
                      <h4>My first MNC experience</h4>
                      <p> In 2023, I embarked on my first venture into a multinational corporation, further enriching my experience and expanding my horizons.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="each-timeline">
                    <span>2024</span>
                    <div className='time-txt'>
                      <h4>From designer to a Full stack dev</h4>
                      <p>And now, in 2024, my journey has reached a remarkable milestone as my transitions seamlessly from a talented designer to a versatile full-stack developer, embodying the epitome of growth and progression in the ever-evolving landscape of technology. With each year marking a chapter in my dramatic narrative, I've stands as a testament to perseverance, ambition, and the relentless pursuit of excellence.</p>
                    </div>
                  </div>
                </li>
                <li></li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="projects">
        <Container>
          <Row>
            <Col md={3}>
              <div>
                <ul>
                  {
                    items.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="each-pro">
                            <img src={item} alt='project1' />
                          </div>
                        </li>
                      )

                    })
                  }
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <ul>
                  {
                    items.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="each-pro">
                            <img src={item} alt='project1' />
                          </div>
                        </li>
                      )

                    })
                  }
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <ul>
                  {
                    items.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="each-pro">
                            <img src={item} alt='project1' />
                          </div>
                        </li>
                      )

                    })
                  }
                </ul>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <ul>
                  {
                    items.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="each-pro">
                            <img src={item} alt='project1' />
                          </div>
                        </li>
                      )

                    })
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ContactForm />
    </>


  )
}

export default Main