import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Main from './components/main/Main';
import About from './components/about/About';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
import Pricing from './components/pricing/Pricing';
import Header from './header/Header';
import Footer from './footer/Footer';
function App() {
  return (
   <>
    
    <BrowserRouter>
    <Header />
    <Routes>
      <Route exact path='/' element={<Main/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/projects' element={<Projects/>} />
      <Route path='/pricing' element={<Pricing />} />
      <Route path='/contact' element={<Contact/>} />
    </Routes>
    <Footer/>
    </BrowserRouter>
   </>
     
   
  );
}

export default App;
