import React from 'react'
import { Container } from 'react-bootstrap'
import './footer.scss'
function Footer() {
  return (
   <section className="footer">
    <Container>
      <p className='text-center text-white'>All copy rights reserved &copy; 2024.</p>
    </Container>
   </section>
  )
}

export default Footer